<template>
  <div class="payment pt20">
    <div class="row form-step__heading">
      <div
        class="number-circle lh35 cl-white brdr-circle align-center weight-700"
        :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
      >
        {{ (isVirtualCart ? 3 : 4) }}
      </div>
      <div class="col-xs-10 col-sm-9 col-md-10 mb10 checkout-step-info-container">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5">
              {{ $t('Payment') }}
            </h3>
          </div>
          <div class="col-xs-12 col-md-5">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit">
                <span class="pr5">
                  {{ $t('Edit payment') }}
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>

          <!-- <div v-if="isActive || isFilled" class="info-box">
            <i class="material-icons icon info-icon">info_outline</i>
            <span>Před zapůjčením, může být vyžadováno <span class="underline">složení peněžní zálohy.</span></span>
          </div> -->

        </div>
        <div class="row" v-if="!isActive && isFilled">
          <div class="col-xs-12 col-sm-9 col-md-11">
            <div v-if="isActive || isFilled" class="info-box">
            <i class="material-icons icon info-icon">info_outline</i>
            <span>Před zapůjčením, může být vyžadováno <span class="underline">složení peněžní zálohy.</span></span>
          </div>
            <div class="row fs16 mb35">
              <div class="col-xs-12 h4">
                <p>
                  {{ payment.firstName }} {{ payment.lastName }}
                </p>
                <p>
                  {{ payment.streetAddress }} {{ payment.apartmentNumber }}
                </p>
                <p>
                  {{ payment.city }} {{ payment.zipCode }}
                </p>
                <p>
                  <span v-if="payment.state">{{ payment.state }}, </span>
                  <span>{{ getCountryName() }}</span>
                </p>
                <div v-if="payment.phoneNumber">
                  <span class="pr15">{{ payment.phoneNumber }}</span>
                  <tooltip>{{ $t('Phone number may be needed by carrier') }}</tooltip>
                </div>
                <!-- <p v-if="generateInvoice">
                  {{ payment.company }} {{ payment.taxIdIco }}
                </p> -->
                <div class="col-xs-12">
                  <h4>{{ $t('Payment method') }}</h4>
                </div>
                <div class="col-md-6 mb15">
                  <label class="radioStyled radioStyled__disabled"> {{ $t(getPaymentMethod().title) }}
                    <input type="radio" value="" checked disabled name="chosen-payment-method">
                    <span class="checkmark" />
                  </label>
                </div>
                <div v-if="discount" class="col-xs-12 mt70">
                  <h4 class="pb5">
                    {{ $t('Discount coupon') }}
                  </h4>
                  <span class="block font-size-18">{{ $t('Discount') }}: <span class="bold">{{ discount.value | price(storeView) }}</span></span>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isActive">
      <div class="col-xs-11 col-sm-9 col-md-10 xspl20">
        <div v-if="isActive || isFilled" class="info-box">
            <i class="material-icons icon info-icon">info_outline</i>
            <span>Před zapůjčením, může být vyžadováno <span class="underline">složení peněžní zálohy.</span></span>
          </div>
        <div class="row payment-info-form">
          <div class="col-xxs-12 col-xs-6 mb10 " v-if="!isVirtualCart && hasShippingData">
            <div class="checkbox-container">
              <base-checkbox
                id="sendToShippingAddressCheckbox"
                v-model="sendToShippingAddress"
              />
              <label for="sendToShippingAddressCheckbox">
                {{ $t('Copy address data from shipping') }}
              </label>
            </div>
          </div>

          <div class="col-xxs-12 col-xs-6 mb25" v-if="hasBillingData()">
            <div class="checkbox-container">
              <base-checkbox
                id="sendToBillingAddressCheckbox"
                v-model="sendToBillingAddress"
              />
              <label for="sendToBillingAddressCheckbox">
                {{ $t('Use my billing data') }}
              </label>
            </div>
          </div>
          <!-- <base-input
            class="col-xs-12 col-sm-6 mb10"
            type="text"
            name="first-name"
            :placeholder="$t('First name *')"
            v-model.trim="payment.firstName"
            @blur="$v.payment.firstName.$touch()"
            autocomplete="given-name"
            :validations="[
              {
                condition: $v.payment.firstName.$error && !$v.payment.firstName.required,
                text: $t('Field is required')
              },
              {
                condition: !$v.payment.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              }
            ]"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb10"
            type="text"
            name="last-name"
            :placeholder="$t('Last name *')"
            v-model.trim="payment.lastName"
            @blur="$v.payment.lastName.$touch()"
            autocomplete="family-name"
            :validations="[{
              condition: $v.payment.lastName.$error && !$v.payment.lastName.required,
              text: $t('Field is required')
            }]"
          />

          <base-input
            class="col-xs-12 mb10"
            type="text"
            name="street-address"
            :placeholder="$t('Street name *')"
            v-model.trim="payment.streetAddress"
            @blur="$v.payment.streetAddress.$touch()"
            autocomplete="address-line1"
            :validations="[{
              condition: $v.payment.streetAddress.$error && !$v.payment.streetAddress.required,
              text: $t('Field is required')
            }]"
          />

          <base-input
            class="col-xs-12 mb10"
            type="text"
            name="apartment-number"
            :placeholder="$t('House/Apartment number *')"
            v-model.trim="payment.apartmentNumber"
            @blur="$v.payment.apartmentNumber.$touch()"
            autocomplete="address-line2"
            :validations="[{
              condition: $v.payment.apartmentNumber.$error && !$v.payment.apartmentNumber.required,
              text: $t('Field is required')
            }]"
          />

          <base-input
            class="col-xs-12 col-sm-12 mb10"
            type="text"
            name="city"
            :placeholder="$t('City *')"
            v-model.trim="payment.city"
            @blur="$v.payment.city.$touch()"
            autocomplete="address-level2"
            :validations="[
              {
                condition: $v.payment.city.$error && !$v.payment.city.required,
                text: $t('Field is required')
              },
              {
                condition: $v.payment.city.$error && $v.payment.city.required,
                text: $t('Please provide valid city name')
              }
            ]"
          /> -->
          <!-- <base-input
            class="col-xs-12 col-sm-6 mb10"
            type="text"
            name="state"
            :placeholder="$t('State / Province')"
            v-model.trim="payment.state"
            autocomplete="address-level1"
          /> -->
          <!-- <base-input
            class="col-xs-12 col-sm-6 mb10"
            type="text"
            name="zip-code"
            :placeholder="$t('Zip-code *')"
            v-model.trim="payment.zipCode"
            @blur="$v.payment.zipCode.$touch()"
            autocomplete="postal-code"
            :validations="[
              {
                condition: $v.payment.zipCode.$error && !$v.payment.zipCode.required,
                text: $t('Field is required')
              },
              {
                condition: !$v.payment.zipCode.minLength,
                text: $t('Zip-code must have at least 3 letters.')
              }
            ]"
          />

          <base-select
            class="col-xs-12 col-sm-6 mb10"
            name="countries"
            :options="countryOptions"
            :selected="payment.country"
            :placeholder="$t('Country *')"
            :validations="[
              {
                condition: $v.payment.country.$error && !$v.payment.country.required,
                text: $t('Field is required')
              }
            ]"
            v-model="payment.country"
            autocomplete="country-name"
            @blur="$v.payment.country.$touch()"
            @change="$v.payment.country.$touch();"
          />

          <base-input
            class="col-xs-12 mb10"
            type="text"
            name="phone-number"
            :placeholder="$t('Phone Number *')"
            v-model.trim="payment.phoneNumber"
            @blur="$v.payment.phoneNumber.$touch()"
            autocomplete="tel"
            :validations="[
              {
                condition: !$v.payment.phoneNumber.phoneValidator,
                text: $t('Please insert phone number in correct format, using numbers')
              },
              {
                condition: !$v.payment.phoneNumber.required,
                text: $t('Field is required')
              }
            ]"
          /> -->

          <!-- <div class="col-xxs-12 col-xs-6 mb15">
            <div class="checkbox-container">
              <base-checkbox
                id="generateInvoiceCheckbox"
                v-model="generateInvoice"
              />
              <label for="generateInvoiceCheckbox">
                {{ $t('I want to generate an invoice for the company') }}
              </label>
            </div>
          </div>

          <template v-if="generateInvoice">
            <base-input
              class="col-xs-12 mb10"
              type="text"
              name="company-name"
              :placeholder="$t('Company name *')"
              v-model.trim="payment.company"
              @blur="$v.payment.company.$touch()"
              autocomplete="organization"
              :validations="[{
                condition: $v.payment.company.$error && !$v.payment.company.required,
                text: $t('Field is required')
              }]"
            />

            <base-input
              class="col-xs-12 mb10"
              type="text"
              name="tax-id-ico"
              :placeholder="$t('Tax ID IČO *')"
              v-model.trim="payment.taxIdIco"
              @blur="$v.payment.taxIdIco.$touch()"
              :autocomplete="$t('Tax ID IČO *')"
              :validations="[
                {
                  condition: $v.payment.taxIdIco.$error && !$v.payment.taxIdIco.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.payment.taxIdIco.minLength,
                  text: $t('Tax IDIco must have at least 8 letters.')
                }
              ]"
            />

            <base-input
              class="col-xs-12 mb10"
              type="text"
              name="tax-id"
              :placeholder="$t('Tax ID')"
              v-model.trim="payment.taxId"
              @blur="$v.payment.taxId.$touch()"
              :autocomplete="$t('Tax ID')"
            /> -->
            <!-- VAT -->
            <!-- <div class="col-xxs-12 col-xs-6 mb15">
              <div class="checkbox-container">
                <base-checkbox
                  id="isUserVatApplicable"
                  v-model="isVatApplicable"
                />
                <label for="isUserVatApplicable"> {{ $t('I do pay VAT') }}</label>
              </div>
            </div>
          </template> -->

          <div class="col-xs-12">
            <h4>
              {{ $t('Payment method') }}
            </h4>
          </div>

          <div v-for="(method, index) in paymentMethods" :key="index" class="col-md-6">
            <label class="radioStyled"> {{ $t(method.title ? method.title : method.name) }}
              <input
                type="radio"
                :value="method.code"
                name="payment-method"
                v-model="payment.paymentMethod"
                @change="$v.payment.paymentMethod.$touch(); changePaymentMethod();"
              >
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error block cl-error h6 mt8" v-if="!$v.payment.paymentMethod.required">{{ $t('Field is required') }}</span>
          <div class="col-xs-12">
            <h4>{{ $t('Discount coupon') }}</h4>
            <discount />
          </div>
        </div>
      </div>
    </div>
    <div class="row btn-row" v-if="isActive">
      <div>
        <div class="row">
          <div>
            <button-full
              @click.native="sendDataToCheckout"
              data-testid="paymentSubmit"
              :disabled="$v.payment.$invalid"
              wrap-style="button-full-wrap-partner"
            >
              {{ $t('Go to the order') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators';
import { Payment } from '@vue-storefront/core/modules/checkout/components/Payment';

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput';
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect';
import ButtonFull from 'theme/components/theme/ButtonFull';
import Tooltip from 'theme/components/core/Tooltip';
import Discount from '../../../Discount';
import { mapGetters } from 'vuex';

const phoneValidator = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /(?=^\+)(^\+[0-9]{9,12}$)|(^[0-9]{9}$)/.test(value);
};

export default {
  components: {
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    ButtonFull,
    Tooltip,
    Discount
  },
  mixins: [Payment],
  computed: {
    ...mapGetters({
      totals: 'cart/getTotals'
    }),
    discount () {
      return this.totals.find(t => t.code === 'discount');
    },
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        };
      });
    }
  },
  validations () {
    if (!this.generateInvoice) {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(2),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          country: {
            required
          },
          streetAddress: {
            required,
            unicodeAlphaNum
          },
          apartmentNumber: {
            required,
            unicodeAlphaNum
          },
          zipCode: {
            required,
            minLength: minLength(3),
            unicodeAlphaNum
          },
          phoneNumber: {
            required,
            phoneValidator
          },
          city: {
            required,
            unicodeAlpha
          },
          paymentMethod: {
            required
          }
        }
      };
    } else {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(2),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          company: {
            required,
            unicodeAlphaNum
          },
          taxId: {},
          taxIdIco: {
            required,
            minLength: minLength(8)
          },
          phoneNumber: {
            required,
            phoneValidator
          },
          country: {
            required
          },
          streetAddress: {
            required,
            unicodeAlphaNum
          },
          apartmentNumber: {
            required,
            unicodeAlphaNum
          },
          zipCode: {
            required,
            minLength: minLength(3),
            unicodeAlphaNum
          },
          city: {
            required,
            unicodeAlpha
          },
          paymentMethod: {
            required
          }
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
$color-gray: #808080;

.checkbox-container {
  display: grid;
  grid-template-columns: 35px auto;
  align-items: center;
  font-size: 14px;
}

.icon {
  vertical-align: bottom;
}

.material-icons.info-icon {
  color: $color-gray;
  font-size: 22px;
}

.info-box {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 0.5rem;
  background-color: #F7D37759;
  padding: 1rem;
}

.underline {
  text-decoration: underline;
}

.payment-info-form {
  margin-top: 1rem;
}

@media (max-width: 575px) {
  .col-xs-hidden {
    display: none;
  }
  .col-xxs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
