<template>
  <component :is="checkoutType()" />
</template>

<script>
import CheckoutOne from './CheckoutOne.vue'
import CheckoutAnyrent from './CheckoutAnyrent.vue';
import CheckoutSbcr from './CheckoutSbcr.vue';
import anyrentConfig from '@vue-storefront/core/partner-config/config.anyrent';
import sbcrConfig from '@vue-storefront/core/partner-config/config.sbcr';
import config from 'config'

// import Checkout from '@vue-storefront/core/pages/Checkout'

// import PersonalDetails from 'theme/components/core/blocks/Checkout/PersonalDetails'
// import Shipping from 'theme/components/core/blocks/Checkout/Shipping'
// import ReturnShipping from 'theme/components/core/blocks/Checkout/ReturnShipping'
// import AdditionalServices from 'theme/components/core/blocks/Checkout/AdditionalServices'
// import Payment from 'theme/components/core/blocks/Checkout/Payment'
// import OrderReview from 'theme/components/core/blocks/Checkout/OrderReview'
// import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
// import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
// import { registerModule } from '@vue-storefront/core/lib/modules'
// import { OrderModule } from '@vue-storefront/core/modules/order'
// import { mapGetters } from 'vuex'
// import config from 'config'

export default {
  components: {
    // AdditionalServices,
    // PersonalDetails,
    // Shipping,
    // ReturnShipping,
    // Payment,
    // OrderReview,
    // CartSummary,
    // ThankYouPage,
    CheckoutOne,
    CheckoutAnyrent,
    CheckoutSbcr
  },
//   computed: {
//     ...mapGetters({
//       getCurrentProduct: 'product/getCurrentProduct',
//     }),
//     partnerName () {
//       return this.getCurrentProduct?.rental_info?.name ?? 'Anyrent'
//     },
//   },
//   mixins: [Checkout],
//   beforeCreate () {
//     registerModule(OrderModule)
//   },
  methods: {
    partnerId () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    checkoutType () {
      console.log('checkoutType we are here');
      switch (this.partnerId()) {
        case anyrentConfig.id:
          return 'checkout-anyrent'
        case sbcrConfig.id:
          return 'checkout-sbcr'
        default: return 'checkout-anyrent'
      }
    },
    isAnyrent () {
      return this.partnerId() === anyrentConfig.id;
    },
    isSbcr () {
      return this.partnerId() === sbcrConfig.id;
    }
  }
}
</script>

<style lang="scss">
//   @import '~theme/css/base/text';
//   @import '~theme/css/variables/colors';
//   @import '~theme/css/helpers/functions/color';
//   @import '~theme/css/variables/assets';
//   @import '~theme/css/helpers/functions/asset';
//   @import '../components/core/blocks/Checkout/checkout.scss';
//   $bg-secondary: color(secondary, $colors-background);
//   $color-tertiary: color(tertiary);
//   $color-secondary: color(secondary);
//   $color-error: color(error);
//   $color-white: color(white);
//   $color-black: color(black);
//   $logo-src: asset(square-logo);
//   $bg-partner: color(partner);

//   #checkout {
//     .number-circle {
//       width: 35px;
//       height: 35px;
//       margin-left: 8px;

//       @media (max-width: 768px) {
//         width: 25px;
//         height: 25px;
//         line-height: 25px;
//       }
//     }
//     .radioStyled {
//       display: block;
//       position: relative;
//       padding-left: 35px;
//       margin-bottom: 12px;
//       cursor: pointer;
//       font-size: 16px;
//       line-height: 30px;
//       -webkit-user-select: none;
//       -moz-user-select: none;
//       -ms-user-select: none;
//       user-select: none;

//       input {
//         position: absolute;
//         opacity: 0;
//         cursor: pointer;
//       }

//       .checkmark {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 25px;
//         width: 25px;
//         border-radius: 50%;
//         border: 1px solid $bg-partner;

//         &:after {
//           content: "";
//           position: absolute;
//           display: none;
//           top: 3px;
//           left: 3px;
//           width: 19px;
//           height: 19px;
//           border-radius: 50%;
//           background: $bg-partner;
//         }
//       }

//       input:checked ~ .checkmark:after {
//         display: block;
//       }

//       &__disabled {
//         cursor: default !important;
//         pointer-events: none !important;
//         & .checkmark {
//           opacity: 0.5;
//         }
//       }
//     }
//   }

//   .flex16 {
//     display: flex;
//     gap: 16px;
//   }

//   .checkout-title {
//     padding-top: 36px;
//     @media (max-width: 767px) {
//       // background-color: $bg-partner;
//       margin-bottom: 25px;

//       h2 {
//         font-size: 36px;
//       }
//       h5 {
//         font-size: 18px;
//       }
//     }
//   }

//   @media (max-width: 575px) {

//     .checkout-title h2 {
//       font-size: 32px;
//     }

//     .partner-logo {
//         margin-left: 20px;
//     }

//     .xspl40 {
//       padding-left: 40px;
//     }
//   }

  
//   @media (max-width: 767px) {
//     .xspl20 {
//       padding-left: 20px;
//     }
//     .container {
//       padding: 0 1rem;
//     }
//   }

//   .checkout-logo {
//     background-image: $logo-src;
//     background-repeat: no-repeat;
//     background-size: contain;
//     width: 124px;
//     height: 124px;
//   }

//   .partner-logo {
//     width: 100px;
//     height: 100px;
//   }

//   .disabled-filled {
//   cursor: default !important;
//   pointer-events: none !important;
//   & .checkmark {
//     opacity: 0.5;
//   }
// }

// @media (min-width: 500px) {
//   .left-side-column {
//     padding-right: 2rem;
//   }
// }

// .checkout-heading {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   * {
//     margin: 0;
//   }
// }
</style>
