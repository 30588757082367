<template>
  <div id="checkout">
    <div class="container">
      <div class="row" v-show="!isThankYouPage">
        <div class="col-sm-12 col-xs-12 col-md-7 pb70 left-side-column">
          <div class="checkout-title">
            <div class="flex16">
              <div class="checkout-logo partner-logo"></div>
              <div class="checkout-heading">
                <h2 class="cl-accent">
                  {{ $t('Ski Bike Centrum Radotín') }}
                </h2>
              </div>
            </div>
          </div>
          <additional-services class="relative " :is-active="activeSection.additionalServices" />
          <personal-details class="relative " :is-active="activeSection.personalDetails" :focused-field="focusedField" />
          <shipping-and-return class="relative cl-accent " :is-active="activeSection.shipping" />
          <!-- <return-shipping class="relative cl-accent " :is-active="activeSection.returnShipping" /> -->
          <payment class="relative cl-accent " :is-active="activeSection.payment" />
          <order-review class="relative cl-accent" :is-active="activeSection.orderReview" />
          <div id="custom-steps" />
        </div>
        <div class="hidden-xs col-sm-5 bg-cl-secondary">
          <cart-summary />
        </div>
      </div>
    </div>
    <thank-you-page v-show="isThankYouPage" />
  </div>
</template>

<script>
import Checkout from '@vue-storefront/core/pages/Checkout'
import PersonalDetails from 'theme/components/core/blocks/Checkout/SBCR/PersonalDetails.vue'
import ShippingAndReturn from 'theme/components/core/blocks/Checkout/SBCR/ShippingAndReturn.vue'
import ReturnShipping from 'theme/components/core/blocks/Checkout/ReturnShipping'
import AdditionalServices from 'theme/components/core/blocks/Checkout/SBCR/AdditionalServices.vue'
import Payment from 'theme/components/core/blocks/Checkout/SBCR/Payment.vue'
import OrderReview from 'theme/components/core/blocks/Checkout/SBCR/OrderReview.vue'
import CartSummary from 'theme/components/core/blocks/Checkout/SBCR/CartSummary.vue'
import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  name: 'PartnerCheckout',
  components: {
    AdditionalServices,
    PersonalDetails,
    ShippingAndReturn,
    ReturnShipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
    }),
    partnerName () {
      return this.getCurrentProduct?.rental_info?.name ?? 'Partner'
    },
  },
  mixins: [Checkout],
  beforeCreate () {
    registerModule(OrderModule)
  },
  methods: {
    notifyPastDate () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Your reservation dates are in past. The cart will be reseted, please select new products and dates'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Shopping cart is empty. Please add some products before entering Checkout'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyOutStock (chp) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: chp.name + this.$t(' is out of stock!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNotAvailable () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Some of the ordered products are not available!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyStockCheck () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Stock check in progress, please wait while available stock quantities are checked'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNoConnection () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'),
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~theme/css/base/text';
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  @import '~theme/css/variables/assets';
  @import '~theme/css/helpers/functions/asset';
  @import '../components/core/blocks/Checkout/checkout.scss';
  $bg-secondary: color(secondary, $colors-background);
  $color-tertiary: color(tertiary);
  $color-secondary: color(secondary);
  $color-error: color(error);
  $color-white: color(white);
  $color-black: color(black);
  $logo-src: asset(square-logo);
  $bg-partner: color(partner);

  #checkout {
    .number-circle {
      width: 35px;
      height: 35px;
      margin-left: 8px;

      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
    .radioStyled {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid $bg-partner;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 3px;
          left: 3px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: $bg-partner;
        }
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      &__disabled {
        cursor: default !important;
        pointer-events: none !important;
        & .checkmark {
          opacity: 0.5;
        }
      }
    }
  }

  .flex16 {
    display: flex;
    gap: 16px;
  }

  .checkout-title {
    padding-top: 36px;
    @media (max-width: 767px) {
      // background-color: $bg-partner;
      margin-bottom: 25px;

      h2 {
        font-size: 36px;
      }
    }
  }

  @media (max-width: 575px) {

    .checkout-title h2 {
      font-size: 32px;
    }

    .partner-logo {
        margin-left: 20px;
    }

    .xspl40 {
      padding-left: 40px;
    }
  }

  
  @media (max-width: 767px) {
    .xspl20 {
      padding-left: 20px;
    }
    .container {
      padding: 0 1rem;
    }
  }

  .checkout-logo {
    background-image: $logo-src;
    background-repeat: no-repeat;
    background-size: contain;
    width: 124px;
    height: 124px;
  }

  .partner-logo {
    width: 100px;
    height: 100px;
  }

  .disabled-filled {
  cursor: default !important;
  pointer-events: none !important;
  & .checkmark {
    opacity: 0.5;
  }
}

@media (min-width: 500px) {
  .left-side-column {
    padding-right: 2rem;
  }
}

.checkout-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  * {
    margin: 0;
  }
}
</style>
