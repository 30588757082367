<script src="Checkout.js"></script>
<template>
  <div class="pt20">
    <pickup-point-detail-modal :pickup-point="pickupPoint" @close="setPickupPoint(null)" v-if="pickupPoint" />
    <div class="row form-step__heading">
      <div
        class="number-circle lh35 cl-white brdr-circle align-center weight-700"
        :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
      >
        3
      </div>
      <div class="col-xs-10 col-sm-9 col-md-10 checkout-step-info-container">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5">
              {{ $t('Pick up') }}
            </h3>
          </div>
          <div class="col-xs-12 col-md-5">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit('shipping')">
                <span class="pr5">
                  {{ $t('Edit shipping') }}
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>
        <div class="row" v-if="!isActive && isFilled">
          <div class="col-xs-12 col-sm-9 col-md-11">
            <div class="row fs16 mb35">
              <div class="col-xs-12 h4" data-testid="shippingAddressSummary">
                <p v-if="!temporaryBlock">
                  {{ shipping.firstName }} {{ shipping.lastName }}
                </p>
                <p v-if="!temporaryBlock">
                  {{ shipping.streetAddress }} {{ shipping.apartmentNumber }}
                </p>
                <p v-if="!temporaryBlock">
                  {{ shipping.zipCode }} {{ shipping.city }}
                </p>
                <p v-if="!temporaryBlock">
                  <span v-if="shipping.state">{{ shipping.state }}, </span>
                  <span>{{ getCountryName() }}</span>
                </p>
                <div v-if="!temporaryBlock">
                  <span class="pr15">{{ shipping.phoneNumber }}</span>
                  <tooltip>{{ $t('Phone number may be needed by carrier') }}</tooltip>
                </div>
                <div class="col-xs-12">
                  <h4>
                    {{ $t('Pick up method') }}
                  </h4>
                </div>
                <div class="col-md-6 mb15">
                  <label :class="['radioStyled', 'radioStyled__disabled']"> {{ getShippingMethod().method_title || '-' }} | {{ (getShippingMethod().price_incl_tax || '- Kč') | price(storeView) }}
                    <input type="radio" value="" checked disabled name="chosen-shipping-method">
                    <span class="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isActive">
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row mb40 xspl40">
          <h4 class="col-xs-12">
            {{ $t('Pick up method') }}
          </h4>
          <div v-for="(method, index) in extendedShippingMethods" :key="index" class="col-md-6 flex align-items-center">
            <label class="radioStyled">
              {{ method.method_title || '-' }} | {{ (method.price_incl_tax || '- Kč') | price(storeView) }}
              <input
                type="radio"
                :value="method.method_id"
                name="shipping-method"
                v-model="shipping.shippingMethod"
                @change="$v.shipping.shippingMethod.$touch(); changeShippingMethod();"
              >
              <button v-if="hasPickupPoint(method)" @click="setPickupPoint(method)" class="brdr-none bg-cl-transparent close pl15">
                <i class="material-icons cl-accent info-icon">info_outline</i>
              </button>
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error" v-if="$v.shipping.shippingMethod.$error && !$v.shipping.shippingMethod.required">
            {{ $t('Field is required') }}
          </span>
        </div>
        <div class="row xspl20">
          <transition name="fade">
            <div v-if="equipmentDelivery">
              <h4>
                {{ $t('Where to deliver?') }}
              </h4>
              <base-checkbox
                v-if="currentUser && hasShippingDetails()"
                class="col-xs-12 mb20"
                id="shipToMyAddressCheckbox"
                v-model="shipToMyAddress"
              >
                {{ $t('Ship to my default address') }}
              </base-checkbox>

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="first-name"
                :placeholder="$t('First name *')"
                v-model.trim="shipping.firstName"
                @blur="$v.shipping.firstName.$touch()"
                autocomplete="given-name"
                :validations="[
                  {
                    condition: $v.shipping.firstName.$error && !$v.shipping.firstName.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.shipping.firstName.minLength,
                    text: $t('Name must have at least 2 letters.')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="last-name"
                :placeholder="$t('Last name *')"
                v-model.trim="shipping.lastName"
                @blur="$v.shipping.lastName.$touch()"
                autocomplete="family-name"
                :validations="[{
                  condition: $v.shipping.lastName.$error && !$v.shipping.lastName.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 mb10"
                type="text"
                name="street-address"
                :placeholder="$t('Street name *')"
                v-model.trim="shipping.streetAddress"
                @blur="$v.shipping.streetAddress.$touch()"
                autocomplete="address-line1"
                :validations="[{
                  condition: $v.shipping.streetAddress.$error && !$v.shipping.streetAddress.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 mb10"
                type="text"
                name="apartment-number"
                :placeholder="$t('House/Apartment number *')"
                v-model.trim="shipping.apartmentNumber"
                @blur="$v.shipping.apartmentNumber.$touch()"
                autocomplete="address-line2"
                :validations="[{
                  condition: $v.shipping.apartmentNumber.$error && !$v.shipping.apartmentNumber.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="city"
                :placeholder="$t('City *')"
                v-model.trim="shipping.city"
                @blur="$v.shipping.city.$touch()"
                autocomplete="address-level2"
                :validations="[
                  {
                    condition: $v.shipping.city.$error && !$v.shipping.city.required,
                    text: $t('Field is required')
                  }/* ,
                  {
                    condition: !$v.shipping.city.matchValidator,
                    text: $t('Currently we can not deliver anywhere else than to Prague')
                  } */
                ]"
              />

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="zip-code"
                :placeholder="$t('Zip-code *')"
                v-model.trim="shipping.zipCode"
                @blur="$v.shipping.zipCode.$touch()"
                autocomplete="postal-code"
                :validations="[
                  {
                    condition: $v.shipping.zipCode.$error && !$v.shipping.zipCode.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.shipping.zipCode.minLength,
                    text: $t('Name must have at least 3 letters.')
                  }
                ]"
              />

              <base-select
                class="col-xs-12 col-sm-6 mb10"
                name="countries"
                :options="countryOptions"
                :selected="shipping.country"
                :placeholder="$t('Country *')"
                :validations="[
                  {
                    condition: $v.shipping.country.$error && !$v.shipping.country.required,
                    text: $t('Field is required')
                  }
                ]"
                v-model="shipping.country"
                autocomplete="country-name"
                @blur="$v.shipping.country.$touch()"
                @change.native="$v.shipping.country.$touch(); changeCountry();"
              />

              <base-input
                class="col-xs-12 mb10 pt15"
                type="text"
                name="phone-number"
                :placeholder="$t('Phone Number *')"
                :validations="[
                  {
                    condition: $v.shipping.phoneNumber.$error && !$v.shipping.phoneNumber.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.shipping.phoneNumber.phoneValidator,
                    text: $t('Please insert phone number in correct format, using numbers')
                  }
                ]"
                @blur="$v.shipping.phoneNumber.$touch()"
                v-model.trim="shipping.phoneNumber"
                autocomplete="tel"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="row btn-row" v-if="isActive">
      <div>
        <div class="row">
          <div>
            <button-full
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout()"
              :disabled="isShippingInvalid"
              wrap-style="button-full-wrap-partner"
            >
              {{ $t('Continue to return') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ButtonFull from 'theme/components/theme/ButtonFull'
import Tooltip from 'theme/components/core/Tooltip'
import MapMixin from '../../../../mixins/MapMixin';
import PickupPointDetailModal from '../PickupPoint/PickupPointDetailModal';

export const partialMatchValidator = (stringToMatch) => {
  return (value) => {
    if (value === undefined) {
      return false
    }
    return value.toLowerCase().startsWith(stringToMatch.toLowerCase())
  }
}

const phoneValidator = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false
  }
  return /(?=^\+)(^\+[0-9]{9,12}$)|(^[0-9]{9}$)/.test(value)
}

export default {
  mixins: [Shipping, MapMixin],
  name: 'Shipping',
  components: {
    ButtonFull,
    Tooltip,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    PickupPointDetailModal,
  },
  data () {
    return {
      temporaryBlock: true,
      test: [],
      equipmentDelivery: false,
      pickupPoint: undefined,
    };
  },
  computed: {
    pickupPoints () {
      const partners = this.$store.getters['partner/getPartners'];
      return partners.map(partner =>
        partner.available_shipping_methods
          .filter(sm => sm.is_pickup_point)
          .map(sm => ({
            methodId: sm.id,
            description: sm.description,
            partnerName: partner.name,
            dropOffDescription: sm.dropoff_description,
            ...sm.pickup_point_info,
          }))).reduce((partner, acc) => [...acc, ...partner], []);
    },
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    storeView () {
      return currentStoreView()
    },
    currentShip () {
      let currentShippingMethod = this.getCurrentShippingMethod()
      return currentShippingMethod;
    },
    extendedShippingMethods () {
      if (this.shippingMethods) {
        return this.shippingMethods
      }
      this.notifyNoShippingMethods();
      return [{
        method_title: '',
        price_incl_tax: ''
      }];
    },
    isShippingInvalid () {
      if (this.$v.shipping.$invalid && this.currentShip && this.currentShip.needs_address) {
        return true
      }
      return false;
    },
  },
  methods: {
    setPickupPoint(method) {
      if (!method) {
        this.pickupPoint = undefined;
      }
      const pickupPoint = this.pickupPoints
        .filter(pp => !!pp && pp.methodId)
        .find(pp => +pp.methodId === +method.method_id);

      if (pickupPoint) {
        this.pickupPoint = pickupPoint;
      }
    },
    hasPickupPoint (method) {
      return method && !!this.pickupPoints.find(pp => +pp.methodId === +method.method_id);
    },
    notifyNoShippingMethods() {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('There is no shipping method currently available.'),
        action1: { label: this.$t('OK') }
      })
    },
  },
  validations: {
    shipping: {
      firstName: {
        required,
        minLength: minLength(2),
        unicodeAlpha
      },
      lastName: {
        required,
        unicodeAlpha
      },
      country: {
        required
      },
      streetAddress: {
        required,
        unicodeAlphaNum
      },
      apartmentNumber: {
        required,
        unicodeAlphaNum
      },
      shippingMethod: {
        required
      },
      zipCode: {
        required,
        minLength: minLength(3),
        unicodeAlphaNum
      },
      phoneNumber: {
        required,
        phoneValidator
      },
      city: {
        required,
        unicodeAlpha,
        /* matchValidator: partialMatchValidator('Praha') */
      }
    }
  }
}
</script>
<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gray: color(gray);

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.material-icons.info-icon {
  color: $color-gray;
  font-size: 22px;
}

</style>
