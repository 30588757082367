<template>
  <button :class="`vs__clear color-black w-100 h100 ${disabled && 'cursor-text'}`" @click="handleOnClick">
    <div class="row cl-accent heading">
      <div class="heading-text">
        <h4 class="align-left">
          {{ text }}
        </h4>
      </div>
      <div class="mt27 float-right col-1">
        <template v-if="!arrowHidden">
          <div v-if="!isOpen" class="center">
            <svg fill="black" xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                 class="vs__open-indicator"
            >
              <path
                fill="black"
                d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
              />
            </svg>
          </div>
          <div v-if="isOpen">
            <svg fill="black" xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
                 class="rotated vs__open-indicator"
            >
              <path fill="black"
                    d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
              />
            </svg>
          </div>
        </template>
      </div>
    </div>
  </button>
</template>
<script>

export default {
  name: 'HeaderDropDown',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: false
    },
    arrowHidden: {
      type: Boolean,
      required: true,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleOnClick () {
      if (this.onClick && !this.disabled) {
        this.onClick(this.isOpen);
      }
    }
  }
};
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/layout/_margin';

$color-matterhorn: color(matterhorn);
$color-black: color(black);

.color-black {
  color: $color-black
}

.mt27 {
  margin-top: 27px;
}

.center {
  align-content: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.cursor-text {
  cursor: text;
  user-select: text;
}

.rotated {
  transform: rotate(180deg);
}

.h100 {
  height: 100%;
}

.heading {
  width: fit-content;
  gap: 1rem;
  @media (max-width: 575px) {
    flex-wrap: nowrap;
    .heading-text {
      flex-basis: 90%;
    }
  }
}
</style>
