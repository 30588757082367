<template>
  <div class="pt20">
    <div class="row form-step__heading">
      <div
        class="number-circle lh35 cl-white brdr-circle align-center weight-700"
        :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
      >
        1
      </div>
      <div class="col-xs-10 col-sm-9 col-md-10 checkout-step-info-container">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5 cl-accent">
              {{ $t('Additional services') }}
            </h3>
          </div>
          <div class="col-xs-12 col-md-5">
            <div class="lh30 flex end-lg" v-if="!isActive && isFilled">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit('returnShipping')">
                <span class="pr5">
                  {{ $t('Edit Additional services') }}
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>

        <div v-if="!isActive && isFilled" class="row unactive">
          <div class="relative">
            <loader-overlay class="row" />
            <div v-if="products && getPossibleAdditionalServices().length > 0">
              <div
                v-for="(product, productIndex) in products"
                :key="`product${productIndex}`"
              >
                <div
                  v-for="(item, itemIndex) in product.items"
                  :key="`item${itemIndex}`"
                  class="row middle-xs"
                >
                  <HeaderDropDown
                    v-if="shouldDisplayHeaderDropDown(item)"
                    :arrow-hidden="!isActive"
                    :disabled="!isActive"
                    :is-open="openedProductsDropdown.hasItem(`${product.id}_${item.id}`) "
                    :text="product.name"
                    :on-click="()=>openedProductsDropdown.toggle(`${product.id}_${item.id}`)"
                  />
                  <!-- services -->
                  <div class="col col-services">
                    <div class="row xspl20 mb15 relative service-row" v-for="(service) in item.services" :key="`service_key${service.serviceKey}`"
                         v-if="openedProductsDropdown.hasItem(`${product.id}_${item.id}`) || service.selected"
                    >
                      <input
                        type="checkbox"
                        class="m0 no-outline checkbox checkbox-input"
                        :id="'checkbox' + service.serviceKey"
                        :disabled="!openedProductsDropdown.hasItem(`${product.id}_${item.id}`)"
                        :checked="service.selected"
                        @input="()=>handleServiceSelect(service)"
                      >
                      <label
                        class="label pl35 lh30 h4 pointer ml10 mt5" :for="'checkbox' + service.serviceKey"
                      >
                        {{ service.name }} {{ renderServiceOptionInOverview(product, service) }} | {{ service.price }}
                        {{ priceDetails }}
                      </label>
                      <div class="relative ml15  row service-row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 pr20">
                          <div class="select-wrapper md-mmr40">
                            <Aselect v-if=" (service.options &&
                                       service.options.length && (openedProductsDropdown.hasItem(`${product.id}_${item.id}`))) "
                                     :value="service.selected_option"
                                     :options="service.options"
                                     :on-option-select="(option)=>{handleOptionSelect(service,option)}"
                                     :disabled="!service.selected"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="products && !getPossibleAdditionalServices().length">
                <span class="pr5">
                  {{ $t('No additional services available') }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div>
              <div class="row">
                <h4 class="align-left cl-accent" v-if="isActive || !isActive && !!userNote">
                  {{ $t('Order note') }}
                </h4>
              </div>
              <div class="row">
                <base-input
                  v-if="isActive"
                  id="order-note"
                  :placeholder="$t('Your note')"
                  :maxlength="200"
                  class="order-note-brdr w-100"
                  :class="{'color-gray':!isActive}"
                  v-model="userNote"
                  type="text"
                />
                <span v-if="!isActive && !!userNote">{{ userNote }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-12" v-if="isActive">
        <p>{{ $t('Choose products for the set and/or additional services:') }}</p>
        <div class="row">
          <div class="relative">
            <loader-overlay class="row" />
            <div v-if="products && getPossibleAdditionalServices().length > 0">
              <div
                v-for="(product, productIndex) in products"
                :key="`product${productIndex}`"
              >
                <div
                  v-for="(item, itemIndex) in product.items"
                  :key="`item${itemIndex}`"
                  class="row middle-xs"
                >
                  <HeaderDropDown
                    v-if="shouldDisplayHeaderDropDown(item)"
                    :arrow-hidden="!isActive"
                    :disabled="!isActive"
                    :is-open="openedProductsDropdown.hasItem(`${product.id}_${item.id}`) "
                    :text="product.name"
                    :on-click="()=>openedProductsDropdown.toggle(`${product.id}_${item.id}`)"
                  />
                  <!-- services -->
                  <div class="col col-services">
                    <div class="row xspl20 mb15 relative service-row" v-for="(service) in item.services" :key="`service_key${service.serviceKey}`"
                         v-if="openedProductsDropdown.hasItem(`${product.id}_${item.id}`) || service.selected"
                    >
                      <input
                        type="checkbox"
                        class="m0 no-outline checkbox checkbox-input"
                        :id="'checkbox' + service.serviceKey"
                        :disabled="!openedProductsDropdown.hasItem(`${product.id}_${item.id}`)"
                        :checked="service.selected"
                        @input="()=>handleServiceSelect(service)"
                      >
                      <label
                        class="label pl35 lh30 h4 pointer ml10 mt5" :for="'checkbox' + service.serviceKey"
                      >
                        {{ service.name }} {{ renderServiceOptionInOverview(product, service) }} | {{ service.price }}
                        {{ priceDetails }}
                      </label>
                      <div class="relative ml15  row service-row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 pr20">
                          <div class="select-wrapper md-mmr40">
                            <Aselect v-if=" (service.options &&
                                       service.options.length && (openedProductsDropdown.hasItem(`${product.id}_${item.id}`))) "
                                     :value="service.selected_option"
                                     :options="service.options"
                                     :on-option-select="(option)=>{handleOptionSelect(service,option)}"
                                     :disabled="!service.selected"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="products && !getPossibleAdditionalServices().length">
                <span class="pr5">
                  {{ $t('No additional services available') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isActive">
      <div class="row">
        <h4 class="align-left cl-accent" v-if="isActive || !isActive && !!userNote">
          {{ $t('Order note') }}
        </h4>
      </div>
      <div class="row">
        <base-input
          v-if="isActive"
          id="order-note"
          :placeholder="$t('Your note')"
          :maxlength="200"
          class="order-note-brdr w-100"
          :class="{'color-gray':!isActive}"
          v-model="userNote"
          type="text"
        />
        <span v-if="!isActive && !!userNote">{{ userNote }}</span>
      </div>
    </div>
    <div class="row mb30 btn-row">
      <template v-if="isActive">
        <div>
          <div class="row">
            <div>
              <button-full
                data-testid="servicesSubmit"
                @click.native="sendDataToCheckout()"
                :disabled="false"
                wrap-style="button-full-wrap-partner"
              >
                {{ $t('Continue to personal details') }}
              </button-full>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import ButtonFull from 'theme/components/theme/ButtonFull';
import Aselect from '../../../theme/anyrent/aselect/Aselect';
import ToggleArray from '../../../theme/anyrent/helpers/ToggleArray';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput';
import debounce from 'lodash-es/debounce'
import LoaderOverlay from '../../LoaderOverlay.vue'
import config from 'config'
import HeaderDropDown from '../../../theme/HeaderDropDown';

export default {
  name: 'AdditionalServices',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BaseInput,
    ButtonFull,
    Aselect,
    LoaderOverlay,
    HeaderDropDown
  },
  data () {
    return {
      isFilled: false,
      activeServices: [],
      openedProductsDropdown: new ToggleArray(),
      activeServicesIdList: [],
      priceDetails: 'Kč vč. DPH'
    };
  },
  computed: {
    storeView () {
      return currentStoreView();
    },
    products () {
      return this.$store.getters['additionalServices/getProducts'];
    },
    userNote: {
      get () {
        return this.$store.getters['checkout/getUserNote'];
      },
      set (note) {
        this.$store.dispatch('checkout/setUserNote', note);
      }
    }

  },
  beforeMount () {
    const hasProducts = this.products && this.products.length
    const hasItems = hasProducts && this.products[0].items && this.products[0].items.length
    if (hasItems) {
      const product = this.products[0]
      const item = this.products[0].items[0]
      const arr = [`${product.id}_${item.id}`]
      this.openedProductsDropdown.setArray(arr)
    }
  },
  methods: {
    displayPartnerData () {
      return config.partnerData.displayPartnerPages;
    },
    updateService: debounce(function () {
      const servicesToUpdate = this.products.flatMap(product => {
        return product.items.map(item => {
          return item.services.filter(service => service.selected)
        })
      }).flat()
      if (servicesToUpdate) {
        this.$store.dispatch('additionalServices/setActive', servicesToUpdate)
      }
    }, 1500),
    shouldDisplayHeaderDropDown (item) {
      return this.isActive || (this.getSelectedServiceOptions(item).length && !this.isActive);
    },
    renderServiceOptionInOverview (product, service) {
      if ((!this.openedProductsDropdown.hasItem(`${product.id}_${service.item_id}`) || !this.isActive) && service.selected_option) {
        return ' - ' + service.selected_option.name;
      }
      return '';
    },
    getSelectedServiceOptions (item) {
      return item.services.filter(service => service.selected);
    },
    handleServiceSelect (service) {
      service.selected = !service.selected;
      this.updateService()
    },
    handleOptionSelect (service, option) {
      if (!service.selected) {
        service.selected = true;
      }
      service.selected_option = option;
      this.updateService()
    },
    getPossibleAdditionalServices () {
      const possibleServices = [];
      for (const product of this.products) {
        for (const item of product.items) {
          for (const service of item.services) {
            possibleServices.push(service);
          }
        }
      }
      return possibleServices;
    },
    sendDataToCheckout () {
      this.openedProductsDropdown.setArray([])
      this.isFilled = true;
      this.$bus.$emit('checkout-after-additionalServices');
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'additionalServices');
    }
  }
};
</script>
<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/base/global_vars';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-active: color(partner);
$color-white: color(white);
$color-gray: color(gray);
$color-partner: color(partner);
$color-matterhorn: color(matterhorn);

.color-gray {
  color: $color-gray;
}

.select-wrapper {
  width: 200px;
}

.select-wrapper::v-deep .vs__search {
  visibility: collapse;
  display: none;
}

.text-align-left {
  text-align: left;
}

.float-left {
  float: left;
}

.pr20{
  padding-right: 20px;
}

.float-right {
  float: right;
}

.label {
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: $color-white;
    border: 2px solid $color-silver;
    cursor: pointer;
  }
}

.checkbox-input {
  position: absolute;
  top: 3px;
  left: 0;
  opacity: 0;

  &:checked + .label {
    &:before {
      background-color: $color-partner;
      border-color: $color-partner;
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      top: 9px;
      left: 5px;
      width: 11px;
      height: 5px;
      border: 3px solid $color-white;
      border-top: none;
      border-right: none;
      background-color: $color-partner;
      transform: rotate(-45deg);
    }
  }

  .ml25 {
    margin-left: 25px;
  }

  .spinner-wrapper {
    width: 40px;
  }

  &:hover,
  &:focus {
    + .label {
      &:before {
        border-color: $color-active;
      }
    }
  }

  &:disabled + .label {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    &:after {
      background-color: $color-gray;
    }

    &:before {
      background-color: $color-gray;
      border: 2px solid $color-gray;
    }

    &:hover,
    &:focus {
      &:before {
        cursor: not-allowed;
      }
    }
  }
}
.order-note {
  color: $color-matterhorn;
  font-size: 18px;
  font-weight: bold;
  min-width: 50px;
}

.service-row {
  gap: 1rem;
}
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-active: color(anyrent);
$color-white: color(white);
$color-partner: color(anyrent);
$color-matterhorn: color(matterhorn);

.order-note-brdr ::v-deep input {
  border: 1px solid $color-silver;
}

.order-note-brdr ::v-deep label {
  left: 10px;
  top: 14px;
}

.text-input {
  padding-left: 10px;
  border: 1px solid $color-silver;
  outline: 0;
  height: 30px;
  color: $color-matterhorn;
}
.text-input:focus {
  outline: 0;
  border: 1px solid $color-partner;
}
.text-input:active {
  border: 1px solid $color-silver;
}
.text-input::placeholder{
  color:  $color-silver;
}
.col-services {
  flex: 1;
}
.font-size-22 {
  font-size: 22px;
}
@media only screen and (max-width: 576px) {
  .select-wrapper {
    width: auto;
    float: none;
  }
}
@media only screen and (max-width: 992px) {
  .md-mmr40{
    margin-left: 42px;
  }
}

</style>
