<template>
  <div class="order-review pt20">
    <div class="row form-step__heading">
      <div>
        <div
          class="number-circle lh35 cl-white brdr-circle align-center weight-700"
          :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
        >
          {{ (isVirtualCart ? 4 : 5) }}
        </div>
      </div>
      <div class="col-xs-10 col-sm-9 col-md-10">
        <div class="row">
          <div class="col-md-12" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0">
              {{ $t('Review order') }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="isActive">
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row mb15 mt20">
          <div class="col-xs-12">
            <p class="xspl20">
              {{ $t('Please check if all data are correct') }}
            </p>
            <div class="row">
              <div class="cartsummary-wrapper">
                <cart-summary />
              </div>
              <base-checkbox
                class="base-checkbox col-xs-12 col-sm-12 col-md-8 bg-cl-secondary p15"
                id="acceptTermsCheckbox"
                @blur="$v.orderReview.terms.$touch()"
                v-model="orderReview.terms"
                :validations="[{
                  condition: !$v.orderReview.terms.required && $v.orderReview.terms.$error,
                  text: $t('Field is required')
                }]"
              >
                <i18n path="I agree to singular" tag="span" for="to Terms and conditions">
                  <span
                    class="link pointer"
                    @click.prevent="$bus.$emit('modal-toggle', 'modal-terms')"
                  >
                    {{ $t('to Terms and conditions') }}
                  </span>
                </i18n>
              </base-checkbox>

              <base-checkbox
                v-if="displayPartnerCheckbox()"
                class="base-checkbox col-xs-12 col-sm-12 col-md-8 bg-cl-secondary p15"
                id="acceptGdpr"
                @blur="$v.orderReview.gdpr.$touch()"
                v-model="orderReview.gdpr"
                :validations="[{
                  condition: !$v.orderReview.gdpr.required && $v.orderReview.gdpr.$error,
                  text: $t('Field is required')
                }]"
              >
                <i18n path="I agree to plural" tag="span" for="to Terms and conditions">
                  <span
                    class="link pointer"
                    @click.prevent="$bus.$emit('modal-toggle', 'modal-gdpr')"
                  >
                    {{ $t('processing of personal data') }}
                  </span>
                </i18n>
              </base-checkbox>

              <base-checkbox
                v-if="displayPartnerCheckbox()"
                class="base-checkbox col-xs-12 col-sm-12 col-md-8 bg-cl-secondary p15"
                id="acceptBiometricData"
                @blur="$v.orderReview.biometricData.$touch()"
                v-model="orderReview.biometricData"
                :validations="[{
                  condition: !$v.orderReview.biometricData.required && $v.orderReview.biometricData.$error,
                  text: $t('Field is required')
                }]"
              >
                <i18n path="I agree to plural" tag="span" for="to Terms and conditions">
                  <span
                    class="link pointer"
                    @click.prevent="$bus.$emit('modal-toggle', 'modal-biometric')"
                  >
                    {{ $t('processing of biometric data') }}
                  </span>
                </i18n>
              </base-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row btn-row" v-show="isActive">
      <div>
        <div class="row">
          <div class="hide-gate">
            <form :action="goPayUrl" method="post" id="gopay-payment-button" ref="form">
              <input type="hidden" name="signature" :value="goPaySignature">
              <button
                class="no-outline button-full block brdr-none w-100 px10 py20 weight-400 h4 cl-bg-puerto-rico cl-black sans-serif fs-medium bg-gopay"
                name="pay"
                type="submit"
              >
                Zaplatit - goPay
              </button>
              <script type="application/javascript" src="https://gw.sandbox.gopay.com/gp-gw/js/embed.js"></script>
            </form>
          </div>
          <div class="col-xs-12 col-md-8 px20">
            <slot name="placeOrderButton">
              <p class="offline-error" v-if="OfflineOnly">
                {{ $t('You cannot place order when internet connection is not available.') }}
              </p>
              <button-full
                @click.native="placeOrder"
                data-testid="orderReviewSubmit"
                class="place-order-btn"
                :disabled="$v.orderReview.$invalid || OfflineOnly"
                wrap-style="button-full-wrap-partner"
              >
                {{ $t('Place the order') }}
              </button-full>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <modal name="modal-terms">
      <p slot="header">
        <terms-and-conditions :mode="'onlyHeader'" />
      </p>
      <div slot="content">
        <terms-and-conditions :mode="'onlyBody'" />
      </div>
    </modal>

    <modal name="modal-gdpr">
      <p slot="header">
        <sbcr-gdpr :mode="'onlyHeader'" />
      </p>
      <div slot="content">
        <sbcr-gdpr :mode="'onlyBody'" />
      </div>
    </modal>

    <modal name="modal-biometric">
      <p slot="header">
        <sbcr-gdpr-data :mode="'onlyHeader'" />
      </p>
      <div slot="content">
        <sbcr-gdpr-data :mode="'onlyBody'" />
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { isServer } from '@vue-storefront/core/helpers'
import Composite from '@vue-storefront/core/mixins/composite'
import VueOfflineMixin from 'vue-offline/mixin'
import { mapGetters } from 'vuex'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import ButtonFull from 'theme/components/theme/ButtonFull'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import Modal from 'theme/components/core/Modal'
import TermsAndConditions from 'theme/components/theme/anyrent/Static/Information/TermsAndConditions'
import SbcrGdpr from '../../../../theme/anyrent/Static/Partners/SbcrGdpr'
import SbcrGdprData from '../../../../theme/anyrent/Static/Partners/SbcrGdprData'

import { OrderReview } from '@vue-storefront/core/modules/checkout/components/OrderReview'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { registerModule } from '@vue-storefront/core/lib/modules'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config';

export default {
  components: {
    BaseCheckbox,
    ButtonFull,
    CartSummary,
    Modal,
    TermsAndConditions,
    SbcrGdpr,
    SbcrGdprData
  },
  data () {
    return {
      goPayUrl: '#404',
      goPaySignature: 'unknown'
    }
  },
  mixins: [OrderReview, Composite, VueOfflineMixin],
  validations: {
    orderReview: {
      terms: {
        required
      },
      gdpr: {
        ...(config.partnerData.displayPartnerPages ? { required: required } : {})
      },
      biometricData: {
      }
    }
  },
  beforeCreate () {
    registerModule(OrderModule)
  },
  computed: {
    payment () {
      return this.$store.getters['checkout/getPaymentDetails']
    },
    ...mapGetters({
      productsInCart: 'cart/getCartItems'
    })
  },
  beforeMount () {
    EventBus.$on('gopay-go-fly', this.onGopay)
    EventBus.$on('setup-gopay-form', this.onSetupGoPay)
    EventBus.$on('setup-gopay-form-failed', this.paymentInitFailed)
  },
  beforeDestroy () {
    EventBus.$off('gopay-go-fly', this.onGopay)
    EventBus.$off('setup-gopay-form', this.onSetupGoPay)
    EventBus.$off('setup-gopay-form-failed', this.paymentInitFailed)
    console.log('%c calling beforeDestroy on component', 'color:#222222')
  },
  methods: {
    displayPartnerCheckbox () {
      return config.partnerData.displayPartnerPages;
    },
    onSetupGoPay (result) {
      this.goPayUrl = result.gw_url
      // this.goPaySignature = result.signature
      this.$refs.form.action = result.gw_url
    },
    onGopay () {
      try {
        EventBus.$emit('notification-progress-start', this.$t('redirecting to payment...'))
      } catch (err) {
        console.log(' failure in notification triggered by eventbus')
        console.error(err)
      }
      this.$refs.form.submit()
    },
    onSuccess () {
      if (!isServer) {
        window.scrollTo(0, 0);
      }
    },
    paymentInitFailed (result) {
      console.error('payment init failed')
      console.error(result)
      try {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: result,
          action1: {label: 'OK', action: this.redirectAway}
        })
      } catch (err) {
        console.log('failed notification in orderview 2')
        console.error(err)
      }
      // timeout
      setTimeout(() => {
        this.redirectAway()
      }, 4000)
    },
    redirectAway () {
      EventBus.$emit('notification-progress-stop')
      this.$router.push(localizedRoute({ name: 'home' }, currentStoreView().storeCode))
    },
    onFailure (result) {
      console.error('failure in order review 2')
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    },
    checkedOutItems () {
      const items = []
      for (const item of this.productsInCart) {
        const checkoutItem = {
          name: item.name,
          slug: item.slug,
          sku: item.sku
        }
        items.push(checkoutItem)
      }
      return items
    }
  }
}
</script>

<style lang="scss" scoped>
  .cartsummary-wrapper {
    @media (min-width: 767px) {
      display: none;
    }
  }
  .overflow {
    overflow-y: auto;
  }
  .bg-gopay {
        background-color: #68b9ff;
  }
  .bg-gopay:hover {
        background-color: #2e9eff;
  }
  .hide-gate {
    display: none;
  }
  .offline-error {
    color: #eb5757;
    text-align: center;
    margin: 16px 0;
    display: inline-block;
  }

  @media (max-width: 575px) {
    .base-checkbox {
      margin-left: 0;
    }
  }
</style>
